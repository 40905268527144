import React from "react";

import Footer from "../../components/footer";
import PageLayout from "../../components/page-layout";

import "../../styles/global.css";
import * as style from "../submission-success.module.css";

import ukraineImage from "../../images/ukraine.png";

const SubmissionSuccessPage = () => {
  return (
    <PageLayout
      title="Call A Colleague - Success"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
    >
      <section className={style.section}>
        <h1>Success!</h1>
        <img src={ukraineImage} width="50" />
        <p>
          Please check your mail in about 5 mins. You will receive a
          confirmation of your booking with further instructions.
        </p>
        <p>Thank you!</p>
      </section>

      <Footer />
    </PageLayout>
  );
};

export default SubmissionSuccessPage;
